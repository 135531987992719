export const SVG_ICONS = [
    {
        key: 'analytic',
        path: '../assets/images/icons/analytic.svg'
    },
    {
        key: 'user_module',
        path: '../assets/images/icons/user-module.svg'
    },
    {
        key: 'business_unit',
        path: '../assets/images/icons/business-unit.svg'
    },
    {
        key: 'picture',
        path: '../assets/images/icons/picture.svg'
    },
    {
        key: 'permission',
        path: '../assets/images/icons/permission.svg'
    },
    {
        key: 'organization',
        path: '../assets/images/icons/organization.svg'
    },
    {
        key: 'filter',
        path: '../assets/images/icons/filter.svg'
    },
    {
        key: 'inbox',
        path: '../assets/images/icons/inbox.svg'
    },
    {
        key: 'company',
        path: '../assets/images/icons/company.svg'
    },
    {
        key: 'individual',
        path: '../assets/images/icons/individual.svg'
    },
    {
        key: 'item_management',
        path: '../assets/images/icons/item-management.svg'
    },
    {
        key: 'add_user',
        path: '../assets/images/icons/add-user.svg'
    },
    {
        key: 'price_book',
        path: '../assets/images/icons/price-book.svg'
    },
    {
        key: 'price_book_active',
        path: '../assets/images/icons/price-book-active.svg'
    },
    {
        key: 'price_book_inactive',
        path: '../assets/images/icons/price-book-inactive.svg'
    },
    {
        key: 'price_book_id',
        path: '../assets/images/icons/price-book-id.svg'
    },
    {
        key: 'empty',
        path: '../assets/images/icons/illustrations/empty.svg'
    },
    {
        key: 'document_template',
        path: '../assets/images/icons/document-template.svg'
    },
    {
        key: 'document',
        path: '../assets/images/icons/document.svg'
    },
    {
        key: 'view_grid',
        path: '../assets/images/icons/view-grid.svg'
    },
    {
        key: 'view_list',
        path: '../assets/images/icons/view-list.svg'
    },
    {
        key: 'form_mode',
        path: '../assets/images/icons/form-mode.svg'
    },
    {
        key: 'add_table',
        path: '../assets/images/icons/add-table.svg'
    },
    {
        key: 'image',
        path: '../assets/images/icons/image.svg'
    },
    {
        key: 'page_break',
        path: '../assets/images/icons/page-break.svg'
    },
    {
        key: 'text',
        path: '../assets/images/icons/text.svg'
    },
    {
        key: 'embed',
        path: '../assets/images/icons/embed.svg'
    },
    {
        key: 'note',
        path: '../assets/images/icons/note.svg'
    },
    {
        key: 'people',
        path: '../assets/images/icons/people.svg'
    },
    {
        key: 'statistic',
        path: '../assets/images/icons/statistic.svg'
    },
    {
        key: 'bold',
        path: '../assets/images/icons/bold.svg'
    },
    {
        key: 'italic',
        path: '../assets/images/icons/italic.svg'
    },
    {
        key: 'underline',
        path: '../assets/images/icons/underline.svg'
    },
    {
        key: 'floor_plan',
        path: '../assets/images/icons/floor-plan.svg'
    },
    {
        key: 'active_circle',
        path: '../assets/images/icons/active-circle.svg'
    },
    {
        key: 'inactive_circle',
        path: '../assets/images/icons/inactive-circle.svg'
    },
    {
        key: 'check_circle',
        path: '../assets/images/icons/check-circle.svg'
    },
    {
        key: 'done',
        path: '../assets/images/icons/illustrations/done.svg'
    },
    {
        key: 'warning',
        path: '../assets/images/icons/error-v3.svg'
    },
    {
        key: 'signature',
        path: '../assets/images/icons/signature.svg'
    },
    {
        key: 'profile',
        path: '../assets/images/icons/profile.svg'
    },
    {
        key: 'lock',
        path: '../assets/images/icons/lock.svg'
    },
    {
        key: 'building',
        path: '../assets/images/icons/building.svg'
    },
    {
        key: 'erase',
        path: '../assets/images/icons/erase.svg'
    },
    {
        key: 'deal_table',
        path: '../assets/images/icons/deal-table.svg'
    },
    {
        key: 'group_permission',
        path: '../assets/images/icons/group-permission.svg'
    },
    {
        key: 'hourglass',
        path: '../assets/images/icons/hourglass.svg'
    },
    {
        key: 'payment',
        path: '../assets/images/icons/payment.svg'
    },
    {
        key: 'push_notification',
        path: '../assets/images/icons/push_notification.svg'
    },
    {
        key: 'invoice',
        path: '../assets/images/icons/invoice.svg'
    },
    {
        key: 'dollar_coin',
        path: '../assets/images/icons/dollar-coin.svg'
    },
    {
        key: 'paper_money',
        path: '../assets/images/icons/paper-money.svg'
    },
    {
        key: 'webhook',
        path: '../assets/images/icons/webhook.svg'
    },
    {
        key: 'case_submission',
        path: '../assets/images/icons/case-submission.svg'
    },
    {
        key: 'terminal',
        path: '../assets/images/icons/terminal.svg'
    },
    {
        key: 'ticket',
        path: '../assets/images/icons/ticket.svg'
    },
    {
        key: 'duration',
        path: '../assets/images/icons/duration.svg'
    },
    {
        key: 'money',
        path: '../assets/images/icons/money.svg'
    },
    {
        key: 'deposit_amount',
        path: '../assets/images/icons/deposit-amount.svg'
    },
    {
        key: 'submission_requirement',
        path: '../assets/images/icons/submission-requirement.svg'
    },
    {
        key: 'my_case',
        path: '../assets/images/icons/my-case.svg'
    },
    {
        key: 'generate_invoice',
        path: '../assets/images/icons/generate-invoice.svg'
    },
    {
        key: 'feedback',
        path: '../assets/images/icons/feedback.svg'
    },
    {
        key: 'feedback_1',
        path: '../assets/images/icons/feedback-1.svg'
    },
    {
        key: 'access_control',
        path: '../assets/images/icons/access-control.svg'
    },
    {
        key: 'total_volume',
        path: '../assets/images/icons/total-volume.svg'
    },
    {
        key: 'total_deals',
        path: '../assets/images/icons/total-deals.svg'
    },
    {
        key: 'total_commission',
        path: '../assets/images/icons/total-commission.svg'
    },
    {
        key: 'info_outline',
        path: '../assets/images/icons/info-outline.svg'
    },
    {
        key: 'commission_outstanding',
        path: '../assets/images/icons/commission-outstanding.svg'
    },
    {
        key: 'tax_amount',
        path: '../assets/images/icons/tax-amount.svg'
    },
    {
        key: 'member_group',
        path: '../assets/images/icons/member-group.svg'
    },
    {
        key: 'payment_setting',
        path: '../assets/images/icons/payment-setting.svg'
    },
    {

        key: 'invoice_setting',
        path: '../assets/images/icons/invoice-setting.svg'
    },
    {
        key: 'activity_log',
        path: '../assets/images/icons/activity-log.svg'
    },
    {
        key: 'download',
        path: '../assets/images/icons/download.svg'
    },
    {
        key: 'forward',
        path: '../assets/images/icons/forward.svg'
    },
    {
        key: 'approval',
        path: '../assets/images/icons/approval.svg'
    },
    {
        key: 'rated_star',
        path: '../assets/images/icons/rated-star.svg'
    },
    {
        key: 'star',
        path: '../assets/images/icons/star.svg'
    },
    {
        key: 'co_broke',
        path: '../assets/images/icons/co-broke.svg'
    },
    {
        key: 'send',
        path: '../assets/images/icons/send.svg'
    },
    {
        key: 'alarm',
        path: '../assets/images/icons/alarm.svg'
    },
    {
        key: 'google_calendar',
        path: '../assets/images/icons/google-calendar.svg'
    },
    {
        key: 'zoom',
        path: '../assets/images/icons/zoom.svg'
    },
    {
        key: 'google_drive',
        path: '../assets/images/icons/google-drive.svg'
    },
    {
        key: 'microsoft_teams',
        path: '../assets/images/icons/microsoft-teams.svg'
    },
    {
        key: 'whatsapp',
        path: '../assets/images/icons/whatsapp.svg'
    },
    {
        key: 'commission_split_matrix',
        path: '../assets/images/icons/commission-split-matrix.svg'
    },
    {
        key: 'promotion_settings',
        path: '../assets/images/icons/promotion-settings.svg'
    },
    {
        key: 'hierarchy',
        path: '../assets/images/icons/hierarchy.svg'
    }, {
        key: 'commission_distribution',
        path: '../assets/images/icons/commission-distribution.svg'
    }, {
        key: 'card_default',
        path: '../assets/images/icons/card-default.svg'
    },
    {
        key: 'kyc',
        path: '../assets/images/icons/kyc.svg'
    },
    {
        key: 'menu',
        path: '../assets/images/icons/menu.svg'
    },
    {
        key: 'ecdd',
        path: '../assets/images/icons/ecdd.svg'
    },
    {
        key: 'country',
        path: '../assets/images/icons/country.svg'
    },
    {
        key:'general',
        path: '../assets/images/icons/general.svg'
    },
    {
        key:'stylus_note',
        path:'../assets/images/icons/stylus_note.svg'
    },
    {
        key:'dynamic_field_add',
        path:'../assets/images/icons/dynamic_field_add.svg'
    },
    {
        key:'share',
        path:'../assets/images/icons/share.svg'
    },
    {
        key:'use_info',
        path:'../assets/images/icons/use-info.svg'
    },
    {
        key:'finance_chip',
        path:'../assets/images/icons/finance_chip.svg'
    },
    {
        key:'source_environment',
        path:'../assets/images/icons/source_environment.svg'
    },
    {
        key:'receipt_long',
        path:'../assets/images/icons/receipt_long.svg'
    },
    {
        key:'commission_settings',
        path:'../assets/images/icons/commission_settings.svg'
    },
    {
        key:'activities',
        path:'../assets/images/icons/activities.svg'
    },
    {
        key:'editable_doc',
        path:'../assets/images/icons/editable_doc.svg'
    },
    {
        key:'form_mode_doc',
        path:'../assets/images/icons/form_mode_doc.svg'
    },
    {
        key:'leaderboard',
        path:'../assets/images/icons/leaderboard.svg'
    },
    {
        key:'property_info',
        path:'../assets/images/icons/property_info.svg'
    },
    {
        key:'reports',
        path:'../assets/images/icons/reports.svg'
    },
    {
        key:'export_csv',
        path:'../assets/images/icons/exports/export-csv.svg'
    },
    {
        key:'export_pdf',
        path:'../assets/images/icons/exports/export-pdf.svg'
    },
    {
        key:'export_xlsx',
        path:'../assets/images/icons/exports/export-xlsx.svg'
    },
    {
        key:'property_address',
        path:'../assets/images/icons/property-address.svg'
    },
    {
        key:'salesperson',
        path:'../assets/images/icons/salesperson.svg'
    },
    {
        key:'salesperson',
        path:'../assets/images/icons/salesperson.svg'
    },
    {
        key:'referral_level',
        path:'../assets/images/icons/referral-level.svg'
    },
    {
        key:'content_block',
        path:'../assets/images/icons/content-block.svg'
    },
    {
        key: 'receipt_list',
        path: '../assets/images/icons/receipt-list.svg'
    },
    {
        key: 'visibility_outline',
        path: '../assets/images/icons/visibility-outline.svg'
    },
    {
        key: 'unlock',
        path: '../assets/images/icons/unlock.svg'
    },
    {
        key:'edit_pen',
        path:'../assets/images/icons/edit-pen.svg'
    },
    {
        key:'arrow_expand',
        path:'../assets/images/arrow-expand.svg'
    },
    {
        key:'property_general_infomation',
        path:'../assets/images/icons/property-general-infomation.svg'
    },
    {
        key:'property_photo',
        path:'../assets/images/icons/property-photo.svg'
    },
    {
        key:'property_transaction_report',
        path:'../assets/images/icons/property-transaction-report.svg'
    },
    {
        key:'property_floor_plan',
        path:'../assets/images/icons/property-floor-plan.svg'
    },
];
